<template>
  <div class="page-manager-asset">
    <b-card title="Quản lý vật dụng">
      <div class="manager-item-search">
        <b-row>
          <b-col lg="4" md="4" xs="12">
            <b-form-group>
              <label class="font-weight-bold font-size-label-custom">Phòng ban</label>
              <v-select @input="getListDeivce" v-model="modelDeviceSeach.organizationBranchId" label="organizationBranchName" :options="listDepartment" :reduce="(department) => department.id">
                <span slot="no-options" @click="$refs.select.open = false">
                    Không có dữ liệu
                  </span>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col lg="3" md="3" xs="12" sm="3">
            <b-form-group>
              <label class="font-weight-bold font-size-label-custom">Tên vật dụng</label>
              <b-form-input v-model="modelDeviceSeach.type" @keyup.enter="getListDeivce" placeholder="Nhập tên vật dụng để tìm kiếm"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="3" md="3" xs="12" sm="3">
            <div class="acction-search-item">
              <b-button @click="getListDeivce"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
              >
                <feather-icon
                    icon="SearchIcon"
                    class="mr-50"
                />
                <span class="align-middle">Tìm kiếm</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
        <b-row>
          <b-col lg="12" md="12" xs="12" sm="12">
            <div class="add-item">
              <b-button :to="{ name: 'add-item'}"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">Thêm mới</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      <b-row>
        <b-col lg="12" md="12" xs="12" sm="12">
          <div class="data-table-asset">
            <span class="custom-span">Có tổng số {{totalCount}} bản ghi</span>
            <b-table :items="ListAllItem"
                     :fields="fieldsItem"
                     :per-page="perPage"
                     :current-page="currentPage"
                     class="mb-0">
              <template #cell(index)="data">
                {{data.index + 1}}
              </template>
              <template #cell(action)="data">
                <b-button @click="allocationEmployee(data.item)" v-b-tooltip.hover.top="'Cấp phát'"
                          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                          variant="flat-info"
                          class="btn-icon rounded-circle"
                >
                  <feather-icon icon="UserCheckIcon" />
                </b-button>
                <b-button @click="editAsset(data.item)" v-b-tooltip.hover.top="'Cập nhật'"
                          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                          variant="flat-success"
                          class="btn-icon rounded-circle"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-button @click="deleteDevice(data.item)" v-b-tooltip.hover.top="'Xóa'"
                          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                          variant="flat-danger"
                          class="btn-icon rounded-circle"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </template>
            </b-table>
            <div class="pagination-item-table mt-2">
              <b-pagination
                  v-model="currentPage"
                  :per-page="perPage"
                  hide-goto-end-buttons
                  :total-rows="rows"
              />
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BButton,
  VBTooltip, BFormGroup, BRow, BCol, BTable, BPagination, BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import * as moment from 'moment'

export default {
  components: {
    BCard,
    BButton,
    BFormGroup,
    BRow,
    BCol,
    BTable,
    BPagination,
    vSelect,
    BFormInput,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      listDepartment: [],
      listStatusAsset: [],
      modelDeviceSeach: {
        deviceType: 1,
        organizationBranchId: null,
        status: null,
        assignStatus: null,
        type: '',
      },
      totalCount: 0,
      ListAllItem: [],
      perPage: 10,
      currentPage: 1,
      fieldsItem: [
        {
          key: 'index', label: 'Stt', thClass: 'custom-th-index',
        },
        {
          key: 'model', label: 'Mã', thClass: 'custom-th-code',
        },
        {
          key: 'type', label: 'Thiết bị',
        },
        {
          key: 'totalQuantity', label: 'Số lượng', thClass: 'custom-th-total-quantity',
        },
        {
          key: 'remainQuantity', label: 'Còn lại', thClass: 'custom-th-total-quantity',
        },
        {
          key: 'importDate', label: 'Thời gian', thClass: 'custom-th-date',
        },
        {
          key: 'action', label: 'Hành động', thClass: 'custom-th-action', tdClass: 'custom-td-action',
        },
      ],
    }
  },
  methods: {
    editAsset(item) {
      this.$router.push({
        name: 'update-item',
        query: {
          id: item.id,
          name: item.type,
        },
      })
    },
    getListDepartment() {
      this.$crm.get('organization-branch-school/find-all-department').then(res => {
        this.listDepartment = res.data
      })
    },
    deleteDevice(item) {
      this.$swal({
        title: 'Xóa vật dụng',
        text: `Bạn có muốn xóa vật dụng ${item.type}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy bỏ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$crm.post(`device/delete/${item.id}`).then(res => {
            this.getListDeivce()
            this.$swal({
              icon: 'success',
              title: 'Xóa vật dụng!',
              text: `Xóa vật dụng ${item.type} thành công`,
              showConfirmButton: false,
              timer: 1500,
            })
          })
        }
      })
    },
    getListDeivce() {
      this.$crm.post('device/find-by-condition', this.modelDeviceSeach).then(res => {
        this.ListAllItem = res.data
        this.totalCount = this.ListAllItem.length
        for (let i = 0; i < this.ListAllItem.length; i++) {
          this.ListAllItem[i].importDate = moment(this.ListAllItem[i].importDate).format('DD-MM-YYYY')
        }
      })
    },
    allocationEmployee(item) {
      this.$router.push({
        name: 'allocation-employee',
        query: {
          id: item.id,
          nameDevice: item.type,
          organizationBranchId: item.organizationBranchId,
        },
      })
    },
  },
  created() {
    this.getListDepartment()
    this.getListDeivce()
  },
  computed: {
    rows() {
      return this.ListAllItem.length
    },
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';
@import '../../@core/scss/custom/manager-item.scss';
@import 'src/@core/scss/vue/libs/vue-sweetalert.scss';
</style>
